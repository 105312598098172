<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div class="fixed z-10 inset-0 overflow-y-auto top-10 reset-password-vue">
    <ValidationObserver v-slot="{ handleSubmit, reset }">
      <form @submit.prevent="handleSubmit(save)" @reset.prevent="reset">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-black opacity-50"></div>
          </div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
          ></span
          >&#8203;

          <div
            aria-labelledby="modal-headline"
            aria-modal="true"
            class="inline-block m-auto align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
            role="dialog"
          >
            <div class="bg-white">
              <div class="sm:flex sm:items-start">
                <div class="w-full text-center sm:text-left">
                  <div class="bg-gray-50 py-6 px-8">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      {{ $t("reset-trading-account-password") }}
                    </h3>
                    <div
                      class="h-7 flex items-center absolute top-5 right-5"
                      @click="$emit('close')"
                    >
                      <button
                        aria-label="Close panel"
                        class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
                        type="reset"
                      >
                        <!-- Heroicon name: x -->
                        <svg
                          class="h-5 w-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 18L18 6M6 6l12 12"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div>
                      <dl>
                        <div class="py-4 px-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt
                            class="text-sm leading-5 font-normal my-auto text-left"
                          >
                            {{ $t("client-portal-password") }}
                          </dt>
                          <dd
                            class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              :name="
                                $t(
                                  'password-must-contain-minimum-8-characters-including-one-uppercase-letter-and-one-number'
                                )
                              "
                              :rules="{
                                required: true,
                                regex: passwordPattern,
                              }"
                            >
                              <div class="rounded-md shadow-sm">
                                <input
                                  id="password_current"
                                  v-model="user_password.user_password"
                                  class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  type="password"
                                />
                              </div>
                              <span class="text-sm text-red-600 h-4">{{
                                errors[0]
                              }}</span>
                              <span
                                v-if="error.status"
                                class="text-sm text-red-600 h-4"
                                >{{ error.message }}</span
                              >
                            </ValidationProvider>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="py-6 px-8 sm:px-6 flex justify-between sm:justify-end border-t"
            >
              <span class="inline-flex rounded-md shadow-sm sm:mr-4">
                <Button
                  class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                  type="submit"
                >
                  {{ $t("reset-password") }}
                </Button>
              </span>
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                  type="reset"
                  @click="$emit('close')"
                >
                  {{ $t("Cancel") }}
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { passwordPattern } from "@/views/choices";

export default {
  name: "ResetPassword",
  data() {
    return {
      user_password: {
        user_password: "",
      },
      error: {
        status: false,
        message: "",
      },
      passwordPattern: passwordPattern(),
    };
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  methods: {
    save() {
      this.$store
        .dispatch("reset_account_password", [this.user_password, this.id])
        .then((resp) => {
          this.$notify({
            group: "foo",
            text: `${this.$t(
              "email-for-resetting-trading-account-password-sent-successfully"
            )}`,
          });
          this.$emit("close");
        })
        .catch((err) => {
          if (err.data.user_password) {
            this.error.status = true;
            this.error.message = err.data.user_password[0];
          } else {
            this.$notify({
              group: "foo",
              text: `${Object.values(err.data)}`,
            });
          }
        });
    },
  },
};
</script>