<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div
        aria-hidden="true"
        class="fixed inset-0 transition-opacity"
        @click="closeModal"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        aria-hidden="true"
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        >&#8203;</span
      >
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        aria-labelledby="modal-headline"
        aria-modal="true"
        class="inline-block align-bottom px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6"
        role="dialog"
      >
        <a href="https://shorturl.at/IS028" target="_blank">
          <img
            :src="require(`../../assets/icm-academy-november.png`)"
            alt="webinar"
          />
        </a>

        <div
          class="h-7 flex items-center absolute top-0 right-0"
          @click="closeModal"
        >
          <button
            aria-label="Close panel"
            class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
          >
            <!-- Heroicon name: x -->
            <svg
              class="h-5 w-5"
              fill="none"
              stroke="#000"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 18L18 6M6 6l12 12"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "IcmAcademyModal",
  methods: {
    closeModal() {
      this.$store.commit("SET_ICM_ACADEMY_MODAL", false);
      this.$emit("close", "false");
    },
  },
};
</script>