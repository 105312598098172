<template>
  <span class="inline-flex rounded-md shadow-sm">
    <router-link :class="btnClass" :to="to" type="button">
      <span>
        {{ buttonText }}
      </span>
    </router-link>
  </span>
</template>

<script>
export default {
  name: "LinkButton",
  props: {
    to: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    btnClass() {
      let baseClass =
        "inline-flex justify-center py-2 px-8 border border-transparent text-sm leading-5 font-normal rounded-md focus:outline-none transition duration-150 ease-in-out";
      switch (this.variant) {
        case "primary":
          return `bg-indigo-600 text-white ${baseClass}`;
        case "secondary":
          return `bg-main-button-color border border-gray-300 ${baseClass}`;
        default:
          return `bg-indigo-600 text-white ${baseClass}`;
      }
    },
  },
};
</script>
