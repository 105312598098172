<template></template>
<script>
import Cookie from "js-cookie";

export default {
  name: "LoginAsUser",
  mounted() {
    if (this.$route.query.token) {
      Cookie.set("auth_token", this.$route.query.token);
      this.$store.commit("AUTH_SUCCESS", [{}, this.$route.query.token]);
      this.$router.replace("/");
    }
  },
};
</script>