<template>
  <button :class="btnClass" type="button" @click="click">
    <svg
      v-if="showIcon"
      class="h-5 mr-2 text-white group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <slot name="svg">
        <path
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </slot>
    </svg>
    <span>
      {{ buttonText }}
    </span>
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    buttonText: {
      type: String,
      required: true,
    },

    variant: {
      type: String,
      default: "primary",
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // Emit a custom event, when the button is clicked.
    click() {
      this.$emit("click");
    },
  },
  computed: {
    btnClass() {
      let baseClass =
        "relative inline-flex items-center px-4 py-2 border  text-sm leading-5 font-medium rounded-md focus:outline-none focus:shadow-outline-indigo hover:bg-indigo-700 transition ease-in-out duration-150 ";

      switch (this.variant) {
        case "primary":
          return `bg-main-button-color ${baseClass} text-white border-transparent`;
        case "secondary":
        default:
          return `bg-main-button-color ${baseClass}`;
      }
    },
  },
};
</script>
