<template>
  <div class="forgot-password-vue">
    <!-- <navigation :show-login="true" :show-language-switcher="true" :show-user-menu="false"></navigation> -->
    <div
      class="forgot-content-wrapper min-h-screen w-full bg-gray-100 flex flex-col justify-center pb-0 pt-0 sm:pb-12 sm:pt-20 sm:px-6 lg:px-8 m-auto"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-2xl leading-9 font-bold text-gray-900">
          {{ $t("forgot-your-password") }}
        </h2>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(restore)">
          <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow rounded-sm sm:px-10 m-4">
              <div class="h-20">
                <label
                  class="block text-sm font-medium leading-5 text-gray-700"
                  for="email"
                >
                  {{ $t("email-address") }}
                </label>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      id="email"
                      v-model="username"
                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      name="email"
                      type="email"
                    />
                    <!--                     :class="errors.email ? 'border-red-300 placeholder-red-300 focus:shadow-outline-red focus:border-red-300' : ''"/>-->
                  </div>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
                <p class="text-sm text-red-600 h-4"></p>
              </div>

              <div class="mt-4">
                <span class="block w-full rounded-md shadow-sm">
                  <button
                    :disabled="isDisabled"
                    class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    type="submit"
                  >
                    {{ $t("restore-password") }}
                  </button>
                </span>
                <p
                  class="flex justify-between mt-2 text-center text-sm leading-5 text-gray-600 max-w"
                >
                  <router-link
                    v-if="$route.path.includes('/ib')"
                    class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    to="/ib/login"
                  >
                    {{ $t("Login") }}
                  </router-link>
                  <router-link
                    v-else
                    class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    to="/mobile/sign-in"
                  >
                    {{ $t("Login") }}
                  </router-link>
                  <template v-if="$route.path.includes('/ib')">
                    <router-link
                      class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      to="/ib/signup"
                    >
                      {{ $t("dont-have-an-account") }}
                    </router-link>
                  </template>
                  <template v-else>
                    <router-link
                      class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      to="/mobile/sign-up"
                    >
                      {{ $t("dont-have-an-account") }}
                    </router-link>
                  </template>
                </p>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";

export default {
  name: "ForgotMobile",
  components: {
    Navigation,
  },
  data() {
    return {
      logoName: process.env.VUE_APP_WHITELABEL,
      username: "",
      isDisabled: false,
    };
  },
  methods: {
    restore() {
      let username = this.username;
      let whitelabel = process.env.VUE_APP_WHITELABEL;
      this.isDisabled = true;
      this.$store
        .dispatch(
          "restore_password",
          whitelabel === "EagleGlobalMarkets" ||
            whitelabel === "OneTradingMarkets" ||
            whitelabel === "OrbitInvest" ||
            whitelabel === "TD365" ||
            whitelabel === "TDSouthAfrica" ||
            whitelabel === "GCCBrokers" ||
            whitelabel === "TradiNext" ||
            whitelabel === "TradeCoreUK"
            ? { username }
            : { username, whitelabel }
        )
        .then((res) => {
          this.isDisabled = false;
          this.$notify({
            group: "foo",
            text: `${this.$t("check-your-email")}`,
          });
        })
        .catch((err) => {
          console.log(err);
          let error = Object.keys(err.data)[0];
          if (error === "username") {
            error = this.$t("user-not-found");
          }
          this.isDisabled = false;
          this.$notify({
            group: "foo",
            text: error,
            type: "warn",
          });
        });
    },
  },
};
</script>