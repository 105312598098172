<template>
  <div class="container mx-auto h-screen pt-10">
    <section class="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
      <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          aria-labelledby="svg-workcation"
          class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          fill="none"
          height="404"
          role="img"
          viewBox="0 0 404 404"
          width="404"
        >
          <title id="svg-workcation">{{ $t("Page-not-found") }}</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              height="20"
              patternUnits="userSpaceOnUse"
              width="20"
              x="0"
              y="0"
            >
              <rect
                class="text-gray-200"
                fill="currentColor"
                height="4"
                width="4"
                x="0"
                y="0"
              />
            </pattern>
          </defs>
          <rect
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
            height="404"
            width="404"
          />
        </svg>

        <div class="relative">
          <blockquote class="mt-8">
            <div
              class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900"
            >
              <p>
                {{ $t("Page-not-found") }}
              </p>
              <router-link
                v-if="type === 'IB'"
                class="font-medium text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                to="/ib/"
              >
                Go back home
              </router-link>
              <router-link
                v-else-if="portal === 'Promena'"
                class="font-medium text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                to="/ib/"
              >
                Go back home
              </router-link>
              <router-link
                v-else
                class="font-medium text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                to="/"
              >
                Go back home
              </router-link>
            </div>
          </blockquote>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Cookie from "js-cookie";

export default {
  data() {
    return {
      type: Cookie.get("type"),
      portal: process.env.VUE_APP_WHITELABEL,
    };
  },
};
</script>