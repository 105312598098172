<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form @submit.prevent="handleSubmit(addAccount)" @reset.prevent="reset">
      <Header>
        <template v-slot:title>
          <span class="text-lg leading-6 font-normal text-gray-900">
            {{ $t("add-a-new-demo-account") }}
          </span>
          <p class="mt-1 max-w-2xl text-sm font-light text-gray-500">
            {{
              $t(
                "choose-your-desired-trading-platform-and-currency-settings-for-your-demo-account"
              )
            }}
          </p>
        </template>
      </Header>
      <div
        class="bg-white shadow rounded-md min-h-screen max-w-3xl m-auto sm:mt-5"
      >
        <div v-if="$store.getters.get_demo_backend_accounts.length === 0">
          <div class="rounded-md bg-yellow-50 sm:p-5 m-8">
            <p class="text-sm leading-5 font-normal text-yellow-800">
              {{ $t("sorry-you-cannot-have-any-more-demo-accounts") }}
            </p>
          </div>
        </div>
        <!-- Replace with your content -->
        <div v-else>
          <div class="sm:px-0 pt-8">
            <dl>
              <div class="sm:px-8 sm:py-2">
                <dt class="text-sm leading-5 font-normal mb-1">
                  {{ $t("select-account") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  <div class="sm:col-span-2 flex">
                    <button
                      v-for="(account, index) in get_demo_backend_accounts"
                      id="account_1"
                      :key="index"
                      :class="
                        selectedAccount === account ? 'border-blue-500' : ''
                      "
                      class="transition cursor-pointer duration-150 ease-in-out p-5 border rounded mr-3 w-full focus:outline-none"
                      type="reset"
                      @click="setAccount(account)"
                    >
                      <img
                        :src="
                          selectedAccount === account
                            ? require(`../../assets/${account.backend.type}-white.png`)
                            : require(`../../assets/${account.backend.type}.png`)
                        "
                        alt="Meta Logo"
                        class="m-auto"
                      />
                    </button>
                  </div>
                </dd>
              </div>
              <div class="sm:px-8 mt-5">
                <dt class="text-sm leading-5 font-normal mb-1">
                  {{ $t("Currency") }}
                </dt>
                <dd
                  class="text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  <div class="sm:col-span-2">
                    <ValidationProvider
                      v-if="selectedAccount.backend"
                      v-slot="{ errors }"
                      name="currency"
                      rules="required"
                    >
                      <v-select
                        v-model="currency"
                        :options="
                          get_demo_backend_accounts.find(
                            (x) => x.backend === selectedAccount.backend
                          ).currencies
                        "
                        :placeholder="
                          $t(
                            'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                          )
                        "
                        label="symbol"
                      ></v-select>
                      <span class="text-sm text-red-600 h-4">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>
                </dd>
              </div>
              <div
                v-if="
                  whitelabel === 'ICMEU' ||
                  whitelabel === 'WLGravity' ||
                  whitelabel === 'ICMCapital' ||
                  whitelabel === 'ICMMENA' ||
                  whitelabel === 'GCCBrokers' ||
                  whitelabel === 'TradiNext'
                "
                class="sm:px-8 mt-5 mb-1"
              >
                <dt class="text-sm leading-5 font-normal">
                  {{ $t("Leverage") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  <div class="sm:col-span-2">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="leverage"
                      rules="required"
                    >
                      <v-select
                        v-model="leverage"
                        :options="leverages"
                        :placeholder="
                          $t(
                            'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                          )
                        "
                        label="name"
                      ></v-select>
                      <span class="text-sm text-red-600 h-4">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>
                </dd>
              </div>
              <div
                v-if="
                  selectedAccount.backend &&
                  (selectedAccount.backend.type === 'MT4' ||
                    selectedAccount.backend.type === 'MT5')
                "
                class="sm:px-8 mt-5"
              >
                <dt class="text-sm leading-5 font-normal sm:col-span-2">
                  {{ $t("deposit-amount") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"
                >
                  <div class="sm:col-span-3">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="deposit_amount"
                      rules="required"
                    >
                      <v-select
                        v-model="depositAmount"
                        :options="depositAmounts"
                        :placeholder="
                          $t(
                            'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                          )
                        "
                        label="name"
                      >
                      </v-select>
                      <span class="text-sm text-red-600 h-4">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>
                </dd>
              </div>
            </dl>
            <p
              class="mt-1 max-w-2xl text-sm font-light text-gray-500 text-center mt-3"
            >
              Choose an amount that reflects your initial live account deposit.
            </p>
          </div>
        </div>
        <div
          v-if="$store.getters.get_demo_backend_accounts.length > 0"
          class="flex-shrink-0 px-8 py-4 space-x-4 flex justify-end"
        >
          <span class="inline-flex rounded-md shadow-sm">
            <Button
              :disabled="isDisabled"
              class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
              type="submit"
            >
              {{ $t("create-account") }}
            </Button>
          </span>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from "vuex";
import SelectMenu from "@/components/SelectMenu";
import { accountTypes, depositAmounts, leverage } from "@/views/choices";
import Header from "@/components/Header";

export default {
  name: "AddDemoAccount",
  components: {
    SelectMenu,
    Header,
  },
  data() {
    return {
      selectedAccount: {},
      currency: "",
      leverage: "",
      accountType: "",
      accountTypes: accountTypes(),
      isDisabled: false,
      leverages: leverage(),
      depositAmount: "",
      whitelabel: process.env.VUE_APP_WHITELABEL,
      depositAmounts: depositAmounts(),
    };
  },
  computed: {
    ...mapGetters(["get_demo_backend_accounts"]),
  },
  created() {
    this.$store.dispatch("get_backends").then(() => {
      if (this.get_demo_backend_accounts) {
        this.selectedAccount = this.get_demo_backend_accounts[0];
      }
    });
  },
  methods: {
    addAccount() {
      this.isDisabled = true;
      const data = {
        backend: this.selectedAccount.backend.id,
        currency: this.currency,
        group: null,
      };
      if (
        this.selectedAccount.backend.type === "MT4" ||
        this.selectedAccount.backend.type === "MT5"
      ) {
        data.demo_deposit_amount = this.depositAmount.id;
      }
      this.$store
        .dispatch("add_account", data)
        .then(() => {
          this.isDisabled = false;
          this.$store.dispatch("account_data");
          const t = this.$t.bind(this);
          this.$notify({
            group: "foo",
            text: t("new-demo-trading-account-is-created"),
          });
          this.$router.replace("/account-summary");
        })
        .catch((err) => {
          this.isDisabled = false;
          if (Object.values(err.data)[0][0].text !== undefined) {
            this.$notify({
              group: "foo",
              text: `${Object.values(err.data)[0][0].text}`,
              type: "warn",
            });
          } else {
            this.$notify({
              group: "foo",
              text: `${Object.values(err.data)[0]}`,
              type: "warn",
            });
          }
        });
    },
    setAccount(backend) {
      // if we select a different account we want to reset input field
      // because they have different options available
      // and we dont want the selected values from previous account
      // to stay selected on the new account
      if (this.selectedAccount !== backend) {
        this.currency = "";
        this.accountType = "";
        this.depositAmount = "";
        this.leverages = "";
      }
      this.selectedAccount = backend;
    },
  },
};
</script>