var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, reset }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addAccount)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('Header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"text-lg leading-6 font-normal text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t("add-a-new-demo-account"))+" ")]),_c('p',{staticClass:"mt-1 max-w-2xl text-sm font-light text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t( "choose-your-desired-trading-platform-and-currency-settings-for-your-demo-account" ))+" ")])]},proxy:true}],null,true)}),_c('div',{staticClass:"bg-white shadow rounded-md min-h-screen max-w-3xl m-auto sm:mt-5"},[(_vm.$store.getters.get_demo_backend_accounts.length === 0)?_c('div',[_c('div',{staticClass:"rounded-md bg-yellow-50 sm:p-5 m-8"},[_c('p',{staticClass:"text-sm leading-5 font-normal text-yellow-800"},[_vm._v(" "+_vm._s(_vm.$t("sorry-you-cannot-have-any-more-demo-accounts"))+" ")])])]):_c('div',[_c('div',{staticClass:"sm:px-0 pt-8"},[_c('dl',[_c('div',{staticClass:"sm:px-8 sm:py-2"},[_c('dt',{staticClass:"text-sm leading-5 font-normal mb-1"},[_vm._v(" "+_vm._s(_vm.$t("select-account"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2 flex"},_vm._l((_vm.get_demo_backend_accounts),function(account,index){return _c('button',{key:index,staticClass:"transition cursor-pointer duration-150 ease-in-out p-5 border rounded mr-3 w-full focus:outline-none",class:_vm.selectedAccount === account ? 'border-blue-500' : '',attrs:{"id":"account_1","type":"reset"},on:{"click":function($event){return _vm.setAccount(account)}}},[_c('img',{staticClass:"m-auto",attrs:{"src":_vm.selectedAccount === account
                          ? require(`../../assets/${account.backend.type}-white.png`)
                          : require(`../../assets/${account.backend.type}.png`),"alt":"Meta Logo"}})])}),0)])]),_c('div',{staticClass:"sm:px-8 mt-5"},[_c('dt',{staticClass:"text-sm leading-5 font-normal mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Currency"))+" ")]),_c('dd',{staticClass:"text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[(_vm.selectedAccount.backend)?_c('ValidationProvider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"options":_vm.get_demo_backend_accounts.find(
                          (x) => x.backend === _vm.selectedAccount.backend
                        ).currencies,"placeholder":_vm.$t(
                          'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                        ),"label":"symbol"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)])]),(
                _vm.whitelabel === 'ICMEU' ||
                _vm.whitelabel === 'WLGravity' ||
                _vm.whitelabel === 'ICMCapital' ||
                _vm.whitelabel === 'ICMMENA' ||
                _vm.whitelabel === 'GCCBrokers' ||
                _vm.whitelabel === 'TradiNext'
              )?_c('div',{staticClass:"sm:px-8 mt-5 mb-1"},[_c('dt',{staticClass:"text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("Leverage"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[_c('ValidationProvider',{attrs:{"name":"leverage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"options":_vm.leverages,"placeholder":_vm.$t(
                          'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                        ),"label":"name"},model:{value:(_vm.leverage),callback:function ($$v) {_vm.leverage=$$v},expression:"leverage"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e(),(
                _vm.selectedAccount.backend &&
                (_vm.selectedAccount.backend.type === 'MT4' ||
                  _vm.selectedAccount.backend.type === 'MT5')
              )?_c('div',{staticClass:"sm:px-8 mt-5"},[_c('dt',{staticClass:"text-sm leading-5 font-normal sm:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t("deposit-amount"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"},[_c('div',{staticClass:"sm:col-span-3"},[_c('ValidationProvider',{attrs:{"name":"deposit_amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"options":_vm.depositAmounts,"placeholder":_vm.$t(
                          'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                        ),"label":"name"},model:{value:(_vm.depositAmount),callback:function ($$v) {_vm.depositAmount=$$v},expression:"depositAmount"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e()]),_c('p',{staticClass:"mt-1 max-w-2xl text-sm font-light text-gray-500 text-center mt-3"},[_vm._v(" Choose an amount that reflects your initial live account deposit. ")])])]),(_vm.$store.getters.get_demo_backend_accounts.length > 0)?_c('div',{staticClass:"flex-shrink-0 px-8 py-4 space-x-4 flex justify-end"},[_c('span',{staticClass:"inline-flex rounded-md shadow-sm"},[_c('Button',{staticClass:"relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo",attrs:{"disabled":_vm.isDisabled,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("create-account"))+" ")])],1)]):_vm._e()])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }