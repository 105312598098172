<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div class="fixed z-10 inset-0 overflow-y-auto change-password-vue">
    <div>
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;

        <div
          aria-labelledby="modal-headline"
          aria-modal="true"
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl w-full"
          role="dialog"
        >
          <div class="bg-white">
            <div class="sm:flex sm:items-start">
              <div class="w-full text-center sm:text-left">
                <div class="bg-gray-50 py-6 px-8">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <span
                      v-if="
                        get_profile.first_name &&
                        get_profile.first_name.length > 0
                      "
                    >
                      {{ $t("welcome") }}
                      {{
                        get_profile.first_name + " " + get_profile.last_name
                      }}!
                    </span>
                  </h3>
                  <div
                    class="h-7 flex items-center absolute top-5 right-5"
                    @click="$emit('close')"
                  >
                    <button
                      aria-label="Close panel"
                      class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
                      type="reset"
                    >
                      <!-- Heroicon name: x -->
                      <svg
                        class="h-5 w-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 18L18 6M6 6l12 12"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <div class="px-6 py-4">
                    <p class="text-sm leading-5 font-medium">
                      {{ $t("ready-to-trade-with-gcc-brokers") }}
                    </p>
                    <p class="text-sm leading-5 font-medium">
                      {{
                        $t(
                          "before-you-can-open-a-live-account-with-us-you-need-to-continue-your-onboarding-process"
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="py-6 px-8 sm:px-6 flex justify-between sm:justify-end border-t"
          >
            <span class="inline-flex rounded-md shadow-sm sm:mr-4">
              <button
                class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                @click="$emit('continue')"
              >
                {{ $t("Continue") }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "GccDemoPopup",
  data() {
    return {
      validation_message: this.$t(
        "password-must-contain-minimum-8-characters-including-one-uppercase-letter-and-one-number"
      ),
    };
  },
  computed: {
    ...mapGetters(["get_profile"]),
  },
};
</script>