<template>
  <div>
    <navigation
      :show-language-switcher="true"
      :show-login="true"
      :show-user-menu="false"
    ></navigation>
    <div
      class="min-h-screen w-full bg-gray-100 flex flex-col justify-center m-auto"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <!-- <img class="mx-auto h-12 w-auto" src="../assets/mark-dark.svg" alt="Tradecore">  -->
        <h2
          class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900"
        >
          {{ $t("change-password") }}
        </h2>
      </div>
      <ValidationObserver v-slot="{ handleSubmit, reset }">
        <form @submit.prevent="handleSubmit(save)" @reset.prevent="reset">
          <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
            <div class="bg-white shadow rounded-sm m-4">
              <div class="px-6 pt-6 pb-2 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm leading-5 font-normal my-auto text-left">
                  {{ $t("new-password") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true, regex: passwordPattern }"
                    name="confirm"
                  >
                    <div class="rounded-md shadow-sm">
                      <input
                        id="password_new"
                        v-model="password_new"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        type="password"
                      />
                    </div>
                    <span
                      v-if="
                        errors[0] === 'confirm' &&
                        (whitelabel === 'ICMVC' ||
                          whitelabel === 'ICMAP' ||
                          whitelabel === 'ICMMU' ||
                          whitelabel === 'ICMCapital' ||
                          whitelabel === 'OTM' ||
                          whitelabel === 'ICMMENA' ||
                          whitelabel === 'VCGMarkets' ||
                          whitelabel === 'ICMTrader' ||
                          whitelabel === 'GCCBrokers')
                      "
                      class="text-sm text-red-600 h-4"
                    >
                      {{
                        $t(
                          "password-must-be-8-to-15-characters-long-contain-four-character-types-lowercase-letters-uppercase-letters-numbers-and-symbols"
                        )
                      }}
                    </span>
                    <span v-else class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
              </div>
              <div class="px-6 pt-6 pb-6 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm leading-5 font-normal my-auto text-left">
                  {{ $t("confirm-new-password") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="password:@confirm|required"
                  >
                    <div class="rounded-md shadow-sm">
                      <input
                        id="password_confirm"
                        v-model="password_confirm"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        type="password"
                      />
                    </div>
                    <span
                      v-if="errors[0] === 'Passwords do not match'"
                      class="text-sm text-red-600 h-4"
                    >
                      {{
                        $t(
                          "your-passwords-do-not-match-please-check-and-try-again"
                        )
                      }}</span
                    >
                    <span v-else class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
              </div>
              <div class="p-4 flex justify-between sm:justify-end border-t">
                <span class="inline-flex rounded-md shadow-sm sm:mr-4">
                  <Button
                    class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </Button>
                </span>
                <span class="inline-flex rounded-md shadow-sm">
                  <button
                    class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="cancel"
                  >
                    {{ $t("Cancel") }}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import { passwordPattern } from "@/views/choices";

export default {
  name: "ChangePassword",
  components: {
    Navigation,
  },
  data() {
    return {
      password_new: "",
      password_confirm: "",
      error: {
        status: false,
        message: "",
      },
      passwordPattern: passwordPattern(),
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  props: {
    accountPassword: {
      type: Boolean,
      required: false,
      default: () => {
        return null;
      },
    },
    id: {
      type: Number,
      required: false,
      default: () => {
        return 0;
      },
    },
  },
  methods: {
    cancel() {
      if (process.env.VUE_APP_WHITELABEL === "Promena") {
        this.$router.push("/ib/login");
      } else {
        this.$router.push("/auth/login");
      }
    },
    save() {
      const data = {
        token: this.$route.query.token,
        data: {
          password1: this.password_new,
          password2: this.password_confirm,
        },
      };
      this.$store
        .dispatch("reset_password", data)
        .then(() => {
          this.$notify({
            group: "foo",
            text: `${this.$t("password-successfully-changed")}`,
          });
          if (process.env.VUE_APP_WHITELABEL === "Promena") {
            this.$router.push("/ib/login");
          } else {
            this.$router.push("/auth/login");
          }
        })
        .catch((err) => {
          const t = this.$t.bind(this);
          var errMessage = `${Object.entries(err.data)[0][1]}`;
          if (
            errMessage ===
            "Password must be 6 to 15 characters long, contain at least one uppercase and one lowercase letter, and one digit. Allowed special characters: -_!@#$%^&*"
          ) {
            errMessage = t(
              "password-must-contain-minimum-8-characters-including-one-uppercase-letter-and-one-number"
            );
          } else if (
            errMessage === "New password must differ from old password"
          ) {
            errMessage = t("New-password-must-differ-from-old-password");
          } else {
            errMessage = `${Object.entries(err.data)[0][1]}`;
          }
          this.$notify({
            group: "foo",
            text: errMessage,
            type: "warn",
          });
        });
    },
  },
};
</script>